'use client';

import { usePathname } from 'next/navigation';
import { useEffect } from 'react';

import { MobileNavBar } from '@/app/components/MobileNavBar';
import { Navbar } from '@/app/components/NavBar';
import useAuthRedirect from '@/hooks/useAuthRedirectPortal';
import useQueryParams from '@/hooks/useQueryParams';
import useSegmentAnalytics from '@/hooks/useSegmentAnalytics';

const CASE_VIEW_PAGE =
  /^\/cases\/([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})(\/.*)?$/;

export default function Layout({ children }: { children: React.ReactNode }) {
  useQueryParams();
  const { loggedIn } = useAuthRedirect();
  const pathname = usePathname();
  const { trackPageView } = useSegmentAnalytics();

  useEffect(() => {
    trackPageViews();
  }, [pathname]);

  const trackPageViews = () => {
    const isCaseViewPage = CASE_VIEW_PAGE.test(pathname);
    const caseId = pathname.split('/')[2]?.split('?')[0];

    /*  Fires a standardized page view event for case pages so as not
     *  to fire a different event for every single case ID
     */
    if (isCaseViewPage) {
      trackPageView('/cases', { caseId });
    } else {
      trackPageView();
    }
  };

  return (
    <div className='flex flex-col items-center w-full min-h-screen'>
      <Navbar loggedIn={loggedIn} />
      <MobileNavBar loggedIn={loggedIn} />
      {children}
    </div>
  );
}
