export const HamburgerIcon = () => (
  <svg
    fill='none'
    height={16}
    viewBox='2 2 12 12'
    width={16}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M0 0h16v16H0z' fill='#fff' fillOpacity={0.01} />
    <path
      clipRule='evenodd'
      d='M1.6 3.2a.533.533 0 0 0 0 1.067h12.8a.533.533 0 0 0 0-1.067H1.6ZM1.067 8c0-.295.238-.533.533-.533h12.8a.533.533 0 0 1 0 1.066H1.6A.533.533 0 0 1 1.067 8Zm0 4.267c0-.295.238-.534.533-.534h12.8a.533.533 0 0 1 0 1.067H1.6a.533.533 0 0 1-.533-.533Z'
      fill='#211F26'
      fillRule='evenodd'
    />
  </svg>
);
