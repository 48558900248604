import { NavigateOptions } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import { useRouter, useSearchParams } from 'next/navigation';

import getPreservedQueryString from '@/utils/getPreservedQueryString';

export function useCustomRouter() {
  const router = useRouter();
  const searchParams = useSearchParams();

  const push = (
    href: string,
    newParam?: Record<string, string>,
    options?: NavigateOptions,
  ) => {
    if (href.startsWith('/')) {
      // only preserve query params for internal urls
      const cleanPath = href.split('?')[0];
      const url = cleanPath + getPreservedQueryString(searchParams, newParam);
      router.push(url, options);
    } else {
      window.location.href = href;
    }
  };

  return { push, back: router.back };
}
