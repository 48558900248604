import { usePathname, useSearchParams } from 'next/navigation';

import analytics from '@/segment';
import getFromLocalStorage from '@/utils/getFromLocalStorage';
import getPageNameFromPath from '@/utils/getPageNameFromPath';

const useSegmentAnalytics = () => {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const getDefaultProperties = () => {
    const source =
      searchParams.get('src') || getFromLocalStorage('attributionSource');
    const attributionId =
      getFromLocalStorage('attributionId') || searchParams.get('gclid');
    const isInternalUser = getFromLocalStorage('internal_user');

    return {
      page_name: getPageNameFromPath(pathname),
      user_source: source || '',
      attribution_id: attributionId || '',
      url: window.location.href,
      internal_user: isInternalUser,
    };
  };

  const getDefaultContext = () => {
    const utmSource = searchParams.get('utm_source') || searchParams.get('src');
    const attributionId =
      searchParams.get('gclid') ||
      searchParams.get('fbclid') ||
      searchParams.get('ttclid');

    const utmData = {
      utm_source: utmSource,
      utm_medium: searchParams.get('utm_medium'),
      utm_campaign: searchParams.get('utm_campaign'),
      utm_content: searchParams.get('utm_content'),
      utm_term: searchParams.get('utm_term'),
      attribution_id: attributionId,
    };

    const impactClickId =
      searchParams.get('irclickid') || getFromLocalStorage('impactClickId');
    const impactData = {
      referrer: {
        type: 'impactRadius',
        id: impactClickId,
      },
    };

    return {
      ...(utmSource && attributionId ? utmData : {}),
      ...(impactClickId ? impactData : {}),
    };
  };

  const trackEvent = (eventName: string, eventProperties?: object) => {
    const defaultProperties = getDefaultProperties();
    const context = getDefaultContext();
    const properties = { ...defaultProperties, ...eventProperties };
    return analytics.track(eventName, properties, context);
  };

  const trackPageView = (
    overridePathname?: string,
    additionalProperties?: object,
  ) => {
    const properties = { ...getDefaultProperties(), ...additionalProperties };
    const context = getDefaultContext();
    analytics.page(overridePathname || pathname, properties, context);
  };

  return { trackEvent, trackPageView };
};

export default useSegmentAnalytics;
