const getPreservedQueryString = (
  currentSearchParams: URLSearchParams,
  newParam?: Record<string, string>,
) => {
  const acceptedParams = [
    'email',
    'src',
    'platform',
    'checkout',
    'referrerUID',
    'eventId',
    'reconnect',
    'onboarding',
    'provider_id',
    'provider_nickname',
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_term',
    'gad_source',
    'gclid',
    'irclickid',
    'sharedid',
    'irpid',
    'irgwc',
    'fbclid',
    'ttclid',
  ];

  const newParams = new URLSearchParams();
  currentSearchParams.forEach((value, key) => {
    if (acceptedParams.includes(key) && value) {
      newParams.append(key, value);
    }
  });

  if (newParam) {
    Object.entries(newParam).forEach(([key, value]) => {
      newParams.append(key, value);
    });
  }

  return newParams.toString() ? `?${newParams.toString()}` : '?';
};

export default getPreservedQueryString;
