import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';

import {
  confirmSignIn,
  getFirebaseAuthToken,
  signInWithAppToken,
} from '@/firebase/auth';
import postMessageToApp from '@/utils/postMessageToApp';

import { useCustomRouter } from './useCustomRouter';

export default function useAuthRedirect() {
  const [authLoading, setAuthLoading] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const router = useCustomRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const setWindowFunction = () => {
    const event = new Event('receivedToken');

    window.storeWebToken = (stringifiedToken: string) => {
      const parsedToken = JSON.parse(stringifiedToken);
      const { token } = parsedToken;

      if (token) {
        sessionStorage.setItem('token', token);
        window.dispatchEvent(event);
      }
    };
  };

  const fetchAuthTokenFromMobileApp = async () => {
    postMessageToApp('send_token');

    return new Promise(resolve => {
      window.addEventListener('receivedToken', (): void => {
        resolve(true);
      });
    });
  };

  useEffect(() => {
    const email = searchParams?.get('email');
    const source = searchParams?.get('src');
    const inMobileApp = source === 'native';

    confirmSignIn(window.location.href, email).then(async user => {
      if (!user) {
        setAuthLoading(true);
        setLoggedIn(false);

        if (inMobileApp) {
          setWindowFunction();

          await fetchAuthTokenFromMobileApp()
            .then(getFirebaseAuthToken)
            .then(verifiedToken => {
              if (verifiedToken) {
                sessionStorage.removeItem('token');
                signInWithAppToken(verifiedToken);
                setLoggedIn(true);
                setAuthLoading(false);
              } else {
                postMessageToApp('authentication_failed');
                postMessageToApp('sign_out');
              }
            });
        } else if (pathname !== '/login') {
          localStorage.setItem('redirectPath', pathname);
          setAuthLoading(false);
          router.push('/login');
        }
      } else {
        setLoggedIn(true);
        setAuthLoading(false);
        if (pathname) router.push(pathname);
      }
    });
  }, [pathname]);

  return {
    authLoading,
    loggedIn,
  };
}
